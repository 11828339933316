"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var guide_1 = require("../../libs/guide");
var isDOMElement_1 = require("../../utils/isDOMElement");
var debounce_1 = require("../../utils/throttle-debounce/debounce");
var props = {
    open: Boolean,
    targetEle: {
        type: [String, Object],
        default: undefined
    },
    interactive: {
        // 蒙层下元素是否可交互
        type: Boolean,
        default: true
    },
    clickable: {
        type: Boolean,
        default: false
    },
    highlight: {
        // 是否高亮目标元素
        type: Boolean,
        default: true
    },
    highlightStyle: Object,
    maskStyle: Object,
    clickMaskToStop: {
        type: Boolean,
        default: false
    },
    tooltipConfig: Object,
    whiteList: Array,
    lockScroll: {
        type: Boolean,
        default: false
    },
    insertDOM: [String, Object]
};
exports.default = (0, composition_api_1.defineComponent)({
    name: 'PtGuide',
    props: props,
    emits: ['clickTarget', 'clickMask', 'clickWhiteList', 'clickTooltipButton', 'start', 'stop'],
    setup: function (props, _a) {
        var emit = _a.emit;
        var targetEle = null;
        var handleClickTarget = function (event) { return emit('clickTarget', event); };
        var handleClickMask = function (event) { return emit('clickMask', event); };
        var handleClickWhiteList = function (event) { return emit('clickWhiteList', event); };
        var handleClickTooltipButton = function (event) { return emit('clickTooltipButton', event); };
        var handleStart = function (event) { return emit('start', event); };
        var handleStop = function (event) { return emit('stop', event); };
        var hasUnMount = false;
        var getStart = function (fromResize) {
            (0, composition_api_1.nextTick)(function () {
                var _a, _b;
                if (props.open && !hasUnMount) {
                    targetEle =
                        typeof props.targetEle === 'string' ? document.querySelector(props.targetEle) : props.targetEle;
                    if (!(0, isDOMElement_1.isDOMElement)(targetEle))
                        targetEle = null;
                    (0, guide_1.start)(targetEle, {
                        interactive: props.interactive,
                        clickable: props.clickable,
                        highlight: props.highlight,
                        highlightStyle: props.highlightStyle,
                        maskStyle: props.maskStyle,
                        clickMaskToStop: props.clickMaskToStop,
                        onClickTarget: handleClickTarget,
                        onClickMask: handleClickMask,
                        onClickWhiteList: handleClickWhiteList,
                        onStart: handleStart,
                        onStop: handleStop,
                        tooltipConfig: !props.tooltipConfig
                            ? undefined
                            : __assign(__assign({}, props.tooltipConfig), { onClickTooltipButton: ((_b = (_a = props === null || props === void 0 ? void 0 : props.tooltipConfig) === null || _a === void 0 ? void 0 : _a.isText3Btn) !== null && _b !== void 0 ? _b : true)
                                    ? handleClickTooltipButton
                                    : undefined }),
                        whiteList: props.whiteList,
                        lockScroll: props.lockScroll,
                        insertDOM: props.insertDOM
                    }, fromResize);
                }
            });
        };
        var _debounceStart = (0, debounce_1.default)(125, false, function () { return getStart(true); });
        (0, composition_api_1.onMounted)(function () {
            window.addEventListener('resize', _debounceStart);
        });
        (0, composition_api_1.onUnmounted)(function () {
            hasUnMount = true;
            window.removeEventListener('resize', _debounceStart);
            (0, guide_1.stop)();
        });
        (0, composition_api_1.watch)(function () { return [props, props.open, props.tooltipConfig, props.targetEle]; }, function () {
            if (!props.open || hasUnMount)
                return (0, guide_1.stop)();
            getStart(false);
        }, { immediate: true });
    }
});
