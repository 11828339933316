"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCampaignReportRouteName = exports.getCampaignDataTrendTip = exports.formatCampaignInsightData = exports.formatCampaignData = exports.getCampaignInfo = void 0;
var i18n_1 = require("@/assets/i18n");
var date_utils_1 = require("@/common/utils/date.utils");
var clone_utils_1 = require("@/common/utils/clone.utils");
var common_utils_1 = require("@common/utils/common.utils");
var permission_1 = require("@/common/utils/permission");
var permissions_config_1 = require("@/common/configs/permissions.config");
var setting_config_1 = require("@/components/engagement/setting/setting.config");
var usergrp_config_1 = require("@/components/users/usergrp-config");
var campaign_config_1 = require("./campaign.config");
var getCampaignInfo = function (campaign, profileInfo) {
    function getGoals() {
        if (!campaign)
            return '';
        return (campaign === null || campaign === void 0 ? void 0 : campaign.goals.reduce(function (acc, cur, index, arr) {
            acc += cur.name;
            arr.length - 1 !== index && (acc += '/');
            return acc;
        }, '')) || '--';
    }
    function getUserGroups() {
        if (!campaign)
            return '';
        return campaign === null || campaign === void 0 ? void 0 : campaign.audiences.reduce(function (acc, cur, index, arr) {
            acc += cur.name;
            arr.length - 1 !== index && (acc += '/');
            return acc;
        }, '');
    }
    function getPageConditions() {
        var _a;
        if (!campaign)
            return '';
        var when = (_a = campaign === null || campaign === void 0 ? void 0 : campaign.conditions[0]) === null || _a === void 0 ? void 0 : _a.when;
        if (!when || when.length === 0 || when[0].type === 'ALL') {
            return i18n_1.default.t('campaign_settings.all_site');
        }
        else {
            var whenResult_1 = '';
            when.forEach(function (item) {
                whenResult_1 += (campaign_config_1.conditionPatternList === null || campaign_config_1.conditionPatternList === void 0 ? void 0 : campaign_config_1.conditionPatternList[item === null || item === void 0 ? void 0 : item.condition]) + ' ';
                whenResult_1 += Array.isArray(item.value) ? item.value.join(', ') : item.value;
                whenResult_1 += '\n\n';
            });
            return whenResult_1;
        }
    }
    function getV2PageConditions() {
        var conditionsString = '';
        campaign.versions.forEach(function (version, index) {
            if (campaign.abtestType !== 'start' && index > 0)
                return;
            var displayConfig = version.displayConfig;
            if (displayConfig.when.length > 0 && displayConfig.when[0]) {
                var condition_1 = displayConfig.when[0].condition;
                if (displayConfig.when[0].type === 'ALL') {
                    conditionsString += i18n_1.default.t('engagement.trigger_when_all');
                }
                else {
                    var conditionText = setting_config_1.default.entryPatternList.find(function (item) { return item.value === condition_1; }).text;
                    var triggerPageText = displayConfig.when
                        .map(function (item) { return item.value; })
                        .join(' ');
                    conditionsString += "".concat(conditionText, " ").concat(triggerPageText, "\n\n");
                }
            }
        });
        return conditionsString;
    }
    function getSessionConditions() {
        var _a;
        if (!campaign)
            return '';
        var sessionStringList = [];
        var session = (_a = campaign === null || campaign === void 0 ? void 0 : campaign.conditions[0]) === null || _a === void 0 ? void 0 : _a.session;
        var sessionChildren = session === null || session === void 0 ? void 0 : session.children;
        if (!sessionChildren || sessionChildren.length === 0) {
            sessionStringList.push(i18n_1.default.t('campaign_settings.all_session'));
        }
        else {
            var audienceValueKey_1 = Object.keys(usergrp_config_1.default.audienceValue);
            sessionStringList = sessionChildren.map(function (filter, index) {
                var _a;
                var name = filter.name, condition = filter.condition, value = filter.value, type = filter.type, patternType = filter.patternType;
                var conditionText = (_a = getConditionText(filter.condition, filter.patternType)) !== null && _a !== void 0 ? _a : '';
                if (type === 'eventName')
                    return i18n_1.default.t('engagement.show_when_session_event', {
                        event: value && value.join(', ')
                    });
                else if (type === 'visitPage')
                    return i18n_1.default.t('engagement.show_when_session_visitpage', {
                        condition: conditionText,
                        value: '\n' + (value === null || value === void 0 ? void 0 : value.join('\n'))
                    });
                else if (audienceValueKey_1.includes(type)) {
                    var valueNameList = value &&
                        usergrp_config_1.default.audienceValue[type] &&
                        value.map(function (v) {
                            var valueItem = usergrp_config_1.default.audienceValue[type].find(function (item) { return item.code === v; });
                            return valueItem.name;
                        });
                    return i18n_1.default.t('engagement.show_when_session_visitproperty', {
                        type: name,
                        condition: conditionText,
                        value: valueNameList && valueNameList.join(', ')
                    });
                }
                else {
                    return i18n_1.default.t('engagement.show_when_session_visitproperty', {
                        type: name,
                        condition: conditionText,
                        value: (name === 'Entry Page' || name === 'URL Source' || name === 'Source Host' ? '\n' : '') +
                            (value === null || value === void 0 ? void 0 : value.join(name === 'Entry Page' || name === 'URL Source' || name === 'Source Host'
                                ? '\n'
                                : ', '))
                    });
                }
            });
        }
        return sessionStringList.join('/n');
    }
    function getV2SessionConditions() {
        var sessionString = '';
        campaign.versions.forEach(function (version, index) {
            if (campaign.abtestType !== 'start' && index > 0)
                return;
            var displayConfig = version.displayConfig;
            if (displayConfig.session) {
                var textArr_1 = [];
                var audienceValueKey_2 = Object.keys(usergrp_config_1.default.audienceValue);
                displayConfig.session.forEach(function (item) {
                    var value = item.value;
                    var children = value.children;
                    var propertyText = '';
                    children.forEach(function (filter, index) {
                        propertyText += index === 0 ? '' : i18n_1.default.t('common.separator');
                        var name = filter.name, value = filter.value, type = filter.type;
                        var conditionText = getConditionText(filter.condition, filter.patternType) || '';
                        if (type === 'eventName') {
                            propertyText +=
                                '<span class="condition">' +
                                    i18n_1.default.t('engagement.show_when_session_event', {
                                        event: value && value.join(', ')
                                    });
                            ('</span>');
                        }
                        else if (type === 'visitPage') {
                            propertyText +=
                                '<span class="condition">' +
                                    i18n_1.default.t('engagement.show_when_session_visitpage', {
                                        condition: conditionText,
                                        value: value && value.join(', ')
                                    }) +
                                    '</span>';
                        }
                        else if (audienceValueKey_2.includes(type)) {
                            var valueNameList = value &&
                                usergrp_config_1.default.audienceValue[type] &&
                                value.map(function (v) {
                                    var valueItem = usergrp_config_1.default.audienceValue[type].find(function (item) { return item.code === v; });
                                    return valueItem.name;
                                });
                            propertyText +=
                                '<span class="condition">' +
                                    i18n_1.default.t('engagement.show_when_session_visitproperty', {
                                        type: name,
                                        condition: conditionText,
                                        value: valueNameList && valueNameList.join(', ')
                                    }) +
                                    '</span>';
                        }
                        else {
                            propertyText +=
                                '<span class="condition">' +
                                    i18n_1.default.t('engagement.show_when_session_visitproperty', {
                                        type: name,
                                        condition: conditionText,
                                        value: value && value.join(', ')
                                    }) +
                                    '</span>';
                        }
                    });
                    textArr_1.push(propertyText);
                });
                if (textArr_1.length > 0) {
                    sessionString += textArr_1.join('and');
                }
            }
        });
        return sessionString || i18n_1.default.t('campaign_settings.all_session');
    }
    function getConditionText(value, patternType) {
        if (patternType === void 0) { patternType = 'STRING'; }
        var conditionText = usergrp_config_1.default.audienceCondition[patternType.toLowerCase()].find(function (item) { return item.code === value; });
        if (conditionText) {
            return conditionText.name;
        }
    }
    function getSchedule() {
        if (!campaign)
            return '';
        var schedule = campaign === null || campaign === void 0 ? void 0 : campaign.schedule[0];
        if (!schedule)
            return '';
        var start_text = '', end_text = '', week_text = '', time_text = '', time_zone = '';
        var versionMeta = schedule.versionMeta;
        // start date
        if (versionMeta.start == '1') {
            start_text = "<span>".concat(i18n_1.default.t('engagement.start_showing_immediately'), "</span>");
        }
        else {
            var startDate = versionMeta.startDate && common_utils_1.default.getFullDate(versionMeta.startDate);
            start_text = "".concat(i18n_1.default.t('engagement.run_from'), " <span> ").concat(startDate, " </span>").concat(i18n_1.default.t('engagement.run_from2')).concat(i18n_1.default.t('engagement.comma'));
        }
        // end date
        if (versionMeta.stop === '1') {
            end_text = "<span>".concat(i18n_1.default.t('engagement.stop_showing_never'), "</span>");
        }
        else {
            var endDate = versionMeta.stopDate && common_utils_1.default.getFullDate(versionMeta.stopDate);
            end_text = "".concat(i18n_1.default.t('engagement.date_to'), " <span> ").concat(endDate, "</span>").concat(i18n_1.default.t('engagement.date_to2'));
        }
        // week and time
        if (versionMeta.delivery !== '1') {
            var weeks = versionMeta.week.filter(function (item) { return item.status; });
            if (weeks.length == 7) {
                week_text = " ".concat(i18n_1.default.t('engagement.comma'), "<span>").concat(i18n_1.default.t('engagement.every_monday_to_sunday'), "</span> ");
            }
            else if (weeks.length == 0) {
            }
            else {
                var weekText = versionMeta.week.reduce(function (acc, cur) {
                    cur.status && acc.push("<span>".concat(i18n_1.default.t("common.week_".concat(cur.code, "_short")), "</span>"));
                    return acc;
                }, []);
                week_text = " ".concat(i18n_1.default.t('engagement.comma')).concat(i18n_1.default.t('engagement.every'), " ").concat(weekText.join(','), " ");
            }
            console.log('update:', schedule.timezone.useVisitor);
            time_text = "".concat(i18n_1.default.t('engagement.from'), " <span>").concat(versionMeta.time.join(" ".concat(i18n_1.default.t('engagement.to'), " ")), "</span> ");
        }
        time_zone = "".concat(schedule.timezone.useVisitor
            ? "(".concat(i18n_1.default.t('engagement.visitors_timezone'), ")")
            : "(GTM ".concat(profileInfo.timeZone, ")"));
        return '<span>' + start_text + end_text + week_text + time_text + time_zone + '</span>';
    }
    function getV2Schedule() {
        var _a, _b, _c, _d;
        var displayConfig = (_b = (_a = campaign === null || campaign === void 0 ? void 0 : campaign.versions) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.displayConfig;
        var versionMeta = ((_c = displayConfig === null || displayConfig === void 0 ? void 0 : displayConfig.meta) === null || _c === void 0 ? void 0 : _c.schedule) || {};
        var start_text = '', end_text = '', week_text = '', time_text = '';
        // start date
        if (versionMeta.radio1 == '1') {
            start_text = "<span>".concat(i18n_1.default.t('engagement.start_showing_immediately'), "</span>");
        }
        else {
            var startDate = versionMeta.startDate && common_utils_1.default.getFullDate(versionMeta.startDate);
            start_text = "".concat(i18n_1.default.t('engagement.run_from'), " <span> ").concat(startDate, " </span>").concat(i18n_1.default.t('engagement.run_from2')).concat(i18n_1.default.t('engagement.comma'));
        }
        // end date
        if (versionMeta.radio2 === '1') {
            end_text = "<span>".concat(i18n_1.default.t('engagement.stop_showing_never'), "</span>");
        }
        else {
            var endDate = versionMeta.endDate && common_utils_1.default.getFullDate(versionMeta.endDate);
            end_text = "".concat(i18n_1.default.t('engagement.date_to'), " <span> ").concat(endDate, "</span>").concat(i18n_1.default.t('engagement.date_to2'));
        }
        // week and time
        if (versionMeta.radio3 !== '1') {
            var weeks = versionMeta.week.filter(function (item) { return item.status; });
            if (weeks.length == 7) {
                week_text = " ".concat(i18n_1.default.t('engagement.comma'), "<span>").concat(i18n_1.default.t('engagement.every_monday_to_sunday'), "</span> ");
            }
            else if (weeks.length !== 0) {
                var weekText = versionMeta.week.reduce(function (acc, cur) {
                    cur.status && acc.push("<span>".concat(cur.text, "</span>"));
                    return acc;
                }, []);
                week_text = " ".concat(i18n_1.default.t('engagement.comma')).concat(i18n_1.default.t('engagement.every'), " ").concat(weekText.join(','), " ");
            }
            time_text = "".concat(i18n_1.default.t('engagement.from'), " <span>").concat(versionMeta.time.join(" ".concat(i18n_1.default.t('engagement.to'), " ")), "</span> ").concat(((_d = displayConfig === null || displayConfig === void 0 ? void 0 : displayConfig.meta) === null || _d === void 0 ? void 0 : _d.timezone.useVisitor)
                ? "(".concat(i18n_1.default.t('engagement.visitors_timezone'), ")")
                : "(GTM ".concat(displayConfig.meta.timezone.profile, ")"));
        }
        return start_text + end_text + week_text + time_text;
    }
    var campaignIsV3 = campaign.engageVersion === '3.0';
    console.log('campaignIsV3', campaignIsV3);
    return [
        {
            name: i18n_1.default.t('campaign_settings.campaign_goals'),
            code: 'goal',
            htmlString: getGoals()
        },
        {
            name: i18n_1.default.t('campaign_settings.target_users'),
            code: 'userGroups',
            htmlString: getUserGroups()
        },
        {
            name: i18n_1.default.t('campaign_settings.session_conditions'),
            code: 'sessionConditions',
            htmlString: campaignIsV3 ? getSessionConditions() : getV2SessionConditions()
        },
        {
            name: i18n_1.default.t('campaign_settings.page_conditions'),
            code: 'pageConditions',
            htmlString: campaignIsV3 ? getPageConditions() : getV2PageConditions()
        },
        {
            name: i18n_1.default.t('campaign_settings.schedule'),
            htmlString: campaignIsV3 ? getSchedule() : getV2Schedule()
        }
    ];
};
exports.getCampaignInfo = getCampaignInfo;
var formatCampaignData = function (campaignData, hasForm) {
    if (hasForm === void 0) { hasForm = true; }
    var types = Object.keys(campaign_config_1.metricConfig);
    var result = {};
    types.forEach(function (type) {
        var _a;
        var typeData = campaignData[type];
        var columnConfig = clone_utils_1.default.deep((_a = campaign_config_1.metricConfig[type]) !== null && _a !== void 0 ? _a : []).filter(function (item) {
            return item.columnKey !== 'engageSubmit' || hasForm;
        });
        columnConfig.forEach(function (item, index) {
            var _a, _b, _c, _d;
            var trendType = 'value';
            var columnKey = item.columnKey, dataType = item.dataType, mainView = item.mainView;
            var _e = (typeData === null || typeData === void 0 ? void 0 : typeData[columnKey]) || {}, current = _e.current, last = _e.last;
            if (columnKey === 'engageGoal') {
                var goalData = (typeData === null || typeData === void 0 ? void 0 : typeData.engageGoal) || [];
                var value_1 = {};
                var pastvalue_1 = {};
                var rate_1 = {};
                var pastrate_1 = {};
                var trend_1 = {};
                goalData.forEach(function (goal) {
                    var _a, _b, _c, _d;
                    var current = goal.current, last = goal.last, id = goal.id;
                    value_1[id] = (_a = current.count) !== null && _a !== void 0 ? _a : 0;
                    pastvalue_1[id] = (_b = last === null || last === void 0 ? void 0 : last.count) !== null && _b !== void 0 ? _b : 0;
                    rate_1[id] = (_c = current === null || current === void 0 ? void 0 : current.rate) !== null && _c !== void 0 ? _c : 0;
                    pastrate_1[id] = (_d = last === null || last === void 0 ? void 0 : last.rate) !== null && _d !== void 0 ? _d : 0;
                    if (rate_1[id] > pastrate_1[id]) {
                        trend_1[id] = '+';
                    }
                    else if (rate_1[id] < pastrate_1[id]) {
                        trend_1[id] = '-';
                    }
                    else {
                        trend_1[id] = '--';
                    }
                });
                Object.assign(item, {
                    value: value_1,
                    pastvalue: pastvalue_1,
                    rate: rate_1,
                    pastrate: pastrate_1,
                    trend: trend_1
                });
            }
            else {
                Object.assign(item, {
                    value: getValue((_a = current === null || current === void 0 ? void 0 : current.count) !== null && _a !== void 0 ? _a : 0, dataType),
                    pastvalue: getValue((_b = last === null || last === void 0 ? void 0 : last.count) !== null && _b !== void 0 ? _b : 0, dataType)
                });
                mainView === 'rate' && (Object.assign(item, {
                    rate: (_c = current === null || current === void 0 ? void 0 : current.rate) !== null && _c !== void 0 ? _c : 0,
                    pastrate: (_d = last === null || last === void 0 ? void 0 : last.rate) !== null && _d !== void 0 ? _d : 0
                }),
                    trendType = 'rate');
                item.trend = getTrend(item, trendType);
            }
        });
        result[type] = columnConfig;
    });
    return result;
    function getTrend(target, type, versionId) {
        if (type === void 0) { type = 'rate'; }
        var cur = target[type] || 0;
        var pas = target['past' + type] || 0;
        if (versionId) {
            if (pas[versionId] === cur[versionId])
                return '--';
            else
                return pas[versionId] > cur[versionId] ? '-' : '+';
        }
        if (pas === cur)
            return '--';
        else
            return pas > cur ? '-' : '+';
    }
    function getValue(value, dataType) {
        return dataType === 'timestamp' ? date_utils_1.default.getDuration(value) : typeof value === 'number' ? Math.floor(value * 100) / 100 : value;
    }
};
exports.formatCampaignData = formatCampaignData;
var formatCampaignInsightData = function (campaignData, metricList) {
    var result = [];
    var columnConfig = clone_utils_1.default.deep(metricList);
    return columnConfig.map(function (column) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var userCount = campaignData.userCount;
        var _j = (userCount === null || userCount === void 0 ? void 0 : userCount[column.funName]) || {}, current = _j.current, last = _j.last;
        var trendType = 'value';
        if (column.funName !== 'engageGoal') {
            Object.assign(column, {
                value: getValue((_a = current === null || current === void 0 ? void 0 : current.count) !== null && _a !== void 0 ? _a : 0, column.dataType),
                pastvalue: getValue((_b = last === null || last === void 0 ? void 0 : last.count) !== null && _b !== void 0 ? _b : 0, column.dataType)
            });
            column.columnKey !== 'engageView' && (Object.assign(column, {
                rate: (_c = current === null || current === void 0 ? void 0 : current.rate) !== null && _c !== void 0 ? _c : 0,
                pastrate: (_d = last === null || last === void 0 ? void 0 : last.rate) !== null && _d !== void 0 ? _d : 0
            }),
                trendType = 'rate');
            column.trend = getTrend(column, trendType);
        }
        else if (userCount.engageGoal.length > 0) {
            var goalData = userCount.engageGoal.find(function (goal) { return goal.id === column.columnKey.split('_')[1]; }) || {};
            var current_1 = goalData.current, last_1 = goalData.last;
            var value = (_e = current_1 === null || current_1 === void 0 ? void 0 : current_1.count) !== null && _e !== void 0 ? _e : 0;
            var pastvalue = (_f = last_1 === null || last_1 === void 0 ? void 0 : last_1.count) !== null && _f !== void 0 ? _f : 0;
            var rate = (_g = current_1 === null || current_1 === void 0 ? void 0 : current_1.rate) !== null && _g !== void 0 ? _g : 0;
            var pastrate = (_h = last_1 === null || last_1 === void 0 ? void 0 : last_1.rate) !== null && _h !== void 0 ? _h : 0;
            var trend = '';
            if (rate > pastrate) {
                trend = '+';
            }
            else if (rate < pastrate) {
                trend = '-';
            }
            else {
                trend = '--';
            }
            Object.assign(column, {
                value: value,
                pastvalue: pastvalue,
                rate: rate,
                pastrate: pastrate,
                trend: trend,
                rateDiff: getPercentDiff(rate, pastrate, trend)
            });
        }
        if (column.mainView === 'value') {
            column.diff = getPercentDiff(column.value, column.pastvalue, column.trend);
        }
        else {
            column.diff = getPercentDiff(column.rate, column.pastrate, column.trend);
        }
        return column;
    });
    function getTrend(target, type, versionId) {
        if (type === void 0) { type = 'rate'; }
        var cur = target[type] || 0;
        var pas = target['past' + type] || 0;
        if (versionId) {
            if (pas[versionId] === cur[versionId])
                return '--';
            else
                return pas[versionId] > cur[versionId] ? '-' : '+';
        }
        if (pas === cur)
            return '--';
        else
            return pas > cur ? '-' : '+';
    }
    function getValue(value, dataType) {
        return dataType === 'timestamp' ? date_utils_1.default.getDuration(value) : value;
    }
    function getPercentDiff(current, past, trend) {
        if (trend === '--')
            return 0;
        else if (past === 0) {
            return 100;
        }
        else
            return ((Math.abs(current - past) / past) * 100).toFixed(2);
    }
};
exports.formatCampaignInsightData = formatCampaignInsightData;
var getCampaignDataTrendTip = function (column, timeDiff, goalId) {
    var columnKey = column.columnKey, trend = column.trend;
    var tip = i18n_1.default.t('engagement.compare_tip', { text: timeDiff || '' });
    var compareValue = '';
    var compareRate = '';
    var trendString = trend === '--' ? '-' : trend;
    if (columnKey === 'engageView') {
        compareValue = Math.abs(column.value - column.pastvalue);
        compareRate = getPercentDiff(column.value, column.pastvalue, column.trend) + '%';
    }
    else if (columnKey === 'engageGoal') {
        compareValue = (Math.abs(column.rate[goalId] - column.pastrate[goalId]) * 100).toFixed(2) + '%';
        compareRate =
            getPercentDiff(column.rate[goalId], column.pastrate[goalId], column.trend[goalId]) + '%';
        trendString = column.trend[goalId];
    }
    else {
        compareValue = (Math.abs(column.rate - column.pastrate) * 100).toFixed(2) + '%';
        compareRate = getPercentDiff(column.rate, column.pastrate, column.trend) + '%';
    }
    tip += trendString + compareValue + ' ( ' + trendString + compareRate + ')';
    return tip;
    function getPercentDiff(current, past, trend) {
        if (trend === '--')
            return 0;
        else if (past === 0) {
            return 100;
        }
        else
            return ((Math.abs(current - past) / past) * 100).toFixed(2);
    }
};
exports.getCampaignDataTrendTip = getCampaignDataTrendTip;
var getCampaignReportRouteName = function () {
    var hasV2Permission = permission_1.default.includePermissionWithStore([permissions_config_1.default.campaignReportV2]);
    return hasV2Permission ? 'CampaignReport' : 'EngagementReport';
};
exports.getCampaignReportRouteName = getCampaignReportRouteName;
