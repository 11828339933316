



import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api';
import baseConfig from '@/common/configs/base.config';
import { isSingleSpa, initSingleSpa, sendPostMessage } from '@/common/services/singleSpa.service';
import { useEventBus, useRouter } from '@/hooks';
import cookieUtils from '@/common/utils/cookie.utils';

export default defineComponent({
    name: 'PtxLoading',
    setup() {
        const eventBus = useEventBus();
        const router = useRouter();
        onMounted(() => {
            if (isSingleSpa()) initSingleSpa();
            eventBus.$on('ExperienceStartCallBack', handleInitFinish, false);
        });

        onUnmounted(() => {
            eventBus.$off('ExperienceStartCallBack', handleInitFinish, false);
        });

        function handleInitFinish({
            token,
            uid,
            path,
            cookieInfo
        }: {
            token: string;
            sid: string;
            uid: string;
            path: string;
            cookieInfo?: { [key: string]: string };
        }) {
            if (!token || !uid) {
                sendPostMessage('ExperienceLogout');
                throw new Error(`[Experience] init failed. token: ${Boolean(token)}, uid: ${uid}`);
            }
            localStorage.setItem(baseConfig.ACCOUNT_ID, uid);
            localStorage.setItem(baseConfig.ACCOUNT_TOKEN, token);
            if (cookieInfo) {
                Object.keys(cookieInfo).forEach(key => {
                    cookieUtils.setItem(key, cookieInfo[key]);
                });
            }
            router.push({ path });
        }
    }
});
