import type { Properties } from './types';
export type StyleObject = Properties<string | number>;

function formatStringToCamelCase(str: string) {
    const splitted = str.split('-');
    if (splitted.length === 1) return splitted[0];
    return (
        splitted[0] +
        splitted
            .slice(1)
            .map(word => word[0].toUpperCase() + word.slice(1))
            .join('')
    );
};

const upperCaseReg = /[A-Z]/;
function formatCamelCaseStringToNormal(str: string) {
    let outStr = '';
    for(let char of str){
        if (upperCaseReg.test(char)) outStr += `-${char.toLowerCase()}`;
        else outStr += char;
    }
    return outStr;
};

export function getStyleObjectFromString(str: string) {
    const style: Properties<string> = {};
    str.split(';').forEach(el => {
        const [property, value] = el.split(':');
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty as 'color'] = value.trim();
    });
    return style;
};

export function formatStyleObjectToString(styleObj: StyleObject) {
    const styleString = Object.entries(styleObj).map(style => `${formatCamelCaseStringToNormal(style[0])}: ${String(style[1])}`).join(';');
    return styleString;
};