"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var button_vue_1 = require("./src/button.vue");
var button_group_vue_1 = require("./src/button-group.vue");
button_vue_1.default.install = function (Vue) {
    Vue.component(button_vue_1.default.name, button_vue_1.default);
};
button_group_vue_1.default.install = function (Vue) {
    Vue.component(button_group_vue_1.default.name, button_group_vue_1.default);
};
exports.default = { Button: button_vue_1.default, ButtonGroup: button_group_vue_1.default };
